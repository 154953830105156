import * as React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function Header({ h1, h2, text, className, isMain }) {
  return (
    <header className={classNames(className)}>
      <header>
        {h2 && (
          <h2 className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
            {h2}
          </h2>
        )}

        <h1
          className={classNames(
            'md:max-w-[18em] text-4xl sm:font-bold text-gray-900 md:text-6xl sm:text-5xl font-bold leading-none mt-2 md:mt-4',
            isMain ? 'xl:text-7xl' : ''
          )}
        >
          {h1}
        </h1>

        {text && (
          <p className="md:max-w-[35em] mt-3 text-gray-600 text-xl md:text-2xl lg:leading-8">
            {text}
          </p>
        )}
      </header>
    </header>
  );
}

Header.propTypes = {
  h1: PropTypes.string.isRequired,
  h2: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

Header.defaultProps = {
  h2: null,
  className: null,
  text: null,
};

export default Header;
