import * as React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Container from './container';
import CollabIcon from '../images/advantages/collab.svg';
import NicheIcon from '../images/advantages/niche.svg';
import ResearchIcon from '../images/advantages/research.svg';
import TechIcon from '../images/advantages/tech.svg';
import ImprovementIcon from '../images/advantages/improvement.svg';
import QualityIcon from '../images/advantages/quality.svg';
import Header from './header';

function Item({ title, icon: Icon, children, className }) {
  return (
    <li className={classNames('flex items-start space-x-5 md:block md:space-x-0', className)}>
      <div className="flex items-center justify-center w-12 h-12 text-indigo-500 bg-indigo-100 rounded-lg shrink-0 md:w-14 md:h-14">
        <Icon aria-hidden className="w-6 h-6 fill-current md:w-8 md:h-8" />
      </div>
      <div>
        <h2 className="text-xl font-bold text-gray-800 md:text-2xl md:mt-5">{title}</h2>
        <p className="mt-1 text-lg text-gray-600 md:text-xl md:mt-2">{children}</p>
      </div>
    </li>
  );
}

Item.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.elementType.isRequired,
};

Item.defaultProps = {
  className: null,
};

export default function Advantages() {
  return (
    <Container className="mt-12 md:mt-20 lg:mt-28">
      <Header
        h1="How we work"
        h2="Why Volti Studio"
        text="We care about building truly pleasant user experiences, with attention to those small but important details."
      />

      <ul className="mt-10 space-y-8 md:mt-14 lg:gap-x-16 lg:gap-y-14 md:gap-x-14 md:gap-y-12 md:space-y-0 md:grid md:grid-cols-2 xl:grid-cols-3">
        <Item title="Close collaboration" icon={CollabIcon}>
          As a small team, we’re able to really get to know our clients and understand their values
          and vision to provide purpose-driven solutions.
        </Item>
        <Item title="We’re niche" icon={NicheIcon}>
          We focus on digital product design and front-end development, building web-based
          applications, SaaS websites, and landing pages.
        </Item>
        <Item title="Research-driven" icon={ResearchIcon}>
          Whenever possible, we conduct product research to gain insights and challenge our
          assumptions, so we can identify the right problems to solve.
        </Item>
        <Item title="Tech stack specialization" icon={TechIcon}>
          We are exclusively focusing on the JS stack, including React.js, Next.js, Gatsby.js, so we
          can provide expert-level services to our clients.
        </Item>
        <Item title="High quality bar" icon={QualityIcon}>
          Whether it’s visual design or code, we strive to deliver the best quality possible. We
          deeply care about what our clients feel using what we craft.
        </Item>
        <Item title="Continuous improvement" icon={ImprovementIcon}>
          We believe that continuous improvement is more important than perfection. We constantly
          refine the way we work while building better products.
        </Item>
      </ul>
    </Container>
  );
}
