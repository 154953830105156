import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Header from '../components/header';
import Container from '../components/container';
import Advantages from '../components/advantages';

export default function AboutPage() {
  return (
    <Layout>
      <Container className="mt-12 md:mt-20 lg:mt-32">
        <Header
          isMain
          h1="We’re Volti Studio"
          text="We are a small team of designers and developers offering UI/UX design, mobile, and
        web development consulting."
        />

        <StaticImage
          className="mt-8 md:mt-12"
          src="../images/photos/office-wide-16.9.jpg"
          alt="A designer and a developer are discussing their work"
          placeholder="blurred"
          layout="fullWidth"
          quality="90"
        />

        <section className="mt-6 md:mt-20">
          <h2 className="text-sm font-bold tracking-wide text-indigo-600 uppercase md:text-base">
            Our goal
          </h2>

          <p className="md:max-w-[33em]  text-gray-500 text-xl md:text-4xl mt-2 md:mt-4 md:leading-snug">
            We like to see our clients as partners who have our team dedicated to their business for
            extended periods. This ongoing collaboration allows us to really get to know our clients
            and understand their values and vision to provide solutions they and their users are
            100% satisfied with.
          </p>
        </section>
      </Container>

      <Advantages />
    </Layout>
  );
}
